(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("sbInternalMsgBus"), require("sbJsExtends"));
	else if(typeof define === 'function' && define.amd)
		define("sbAuthorizationLib", ["sbInternalMsgBus", "sbJsExtends"], factory);
	else if(typeof exports === 'object')
		exports["sbAuthorizationLib"] = factory(require("sbInternalMsgBus"), require("sbJsExtends"));
	else
		root["sbAuthorizationLib"] = factory(root["sbInternalMsgBus"], root["sbJsExtends"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__858__, __WEBPACK_EXTERNAL_MODULE__153__) {
return 